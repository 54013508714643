<template>
  <v-container
    fluid
    class="pa-0"
  >
    <div
      v-if="news"
      class=""
    >
      <div
        v-if="page!=='blanket-in-partnership-with-humania'"
        :class="xlOnly ? `section1-xl ${page}` : `section1-desk ${page}`"
      >
        <div class="s1-title-desk">
          {{ news.headline }}
        </div>
      </div>
      <div class="news-section">
        <v-container>
          <div
            v-if="page==='blanket-in-partnership-with-humania'"
          >
            <v-row>
              <v-col col="12">
                <h1>{{ news.headline }}</h1>
              </v-col>
            </v-row>
          </div>
          <v-row>
            <v-col cols="12">
              <h2
                v-if="page==='blanket-in-partnership-with-humania'"
                class=" mb-5 mt-md-8"
              >
                {{ news.subHeadlineAlt }}
              </h2>
              <h1
                v-if="page!=='blanket-in-partnership-with-humania'"
                class=" mb-5 mt-md-8"
              >
                {{ news.subHeadline }}
              </h1>
            </v-col>
            <v-col cols="12">
              <div class="pr-md-10">
                <p>
                  <v-icon
                    large
                    class="primary--text mr-1 mr-md-4"
                  >
                    mdi-calendar-month
                  </v-icon>
                  <span class="date">
                    {{ news.readMore.date }}
                  </span>
                </p>
                <div class="text-center pa-4">
                  <v-img
                    :class="`bnc-logo ${page}`"
                    :src="news.logo"
                  ></v-img>
                </div>
                <div
                  v-for="(lines,index) in news.readMore.lines "
                  :key="index"
                  class="mt-6"
                >
                  <span
                    v-if="lines?.heading&&lines.heading!==''"
                    class="s2-text"
                  >
                    {{ lines.heading }} :
                  </span>
                  <p v-if="lines?.heading && lines.heading!==''"></p>
                  <span
                    class="s2-text"
                    v-html="lines.text"
                  >
                  </span>
                  <p></p>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <call-to-action cta-page="/quote"></call-to-action>
    </div>
  </v-container>
</template>

<script>
  import CallToAction from '@/components/CallToAction.vue'

  export default {
    components: { CallToAction },
    data () {
      return {
        news: null,
        page: null,
      }
    },
    computed: {
      xlOnly () {
        return this.$vuetify.breakpoint.xlOnly
      },
      xsOnly () {
        return this.$vuetify.breakpoint.xsOnly
      },
      smOnly () {
        return this.$vuetify.breakpoint.smOnly
      },
      mdOnly () {
        return this.$vuetify.breakpoint.mdOnly
      },
      lgOnly () {
        return this.$vuetify.breakpoint.lgOnly
      },
    },
    mounted () {
      this.news = this.$i18n.t('about.news.pressRelease').find(item => item.link === this.$route.params.page)
      this.page = this.$route.params.page
    }

  }
</script>

<style lang="scss" scoped>
span.date {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
}

.newsarticle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 150%;
}

.section1-desk {
  position: relative;
  height: 650px;
  background-image: url('/img/news/hero-section.jpg');
  background-position: center;
  background-size: cover;
}

.section1-xl {
  position: relative;
  height: 750px;
  background-image: url('/img/news/hero-section.jpg');
  background-position: center;
  background-size: cover;
}

.s1-title-desk {
  position: absolute;
  top: 445px;
  left: 130px;
  font-family: 'Sora';
  font-style: normal;
  font-weight: 800;
  font-size: 52px;
  line-height: 110%;
  letter-spacing: -0.511589px;
  color: #FFFFFF;
}

.section2-desk {
  background: #FFFFFF;
  padding-top: 93px;
  padding-left: 147px;
  padding-right: 147px;
}

.s2-title {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.424019px;
  color: #292F36;
}

.s2-sub {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 120%;
  letter-spacing: -0.424019px;
  color: #292F36;
}

.s2-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #292F36;
}

.s2-big-text {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 110%;
  letter-spacing: -0.424019px;
  color: #005F6C;
}

.section3-desk {
  height: 900px;
  background-image: url('/img/secondary-knit.jpg');
  background-position: center;
  background-size: cover;
  padding-top: 75px;
  padding-left: 165px;
  padding-right: 165px;
}

.s3-title {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 800;
  font-size: 52px;
  line-height: 110%;
  letter-spacing: -0.511589px;
  color: #FFFFFF;
}

.s3-sub {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 24px;
  /* identical to box height, or 114% */

  text-align: center;

  /* white */

  color: #FFFFFF;
}

.s3-text {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #FFFFFF;
}

.section4-desk {
  padding-top: 79px;
  padding-left: 131px;
  padding-right: 131px;
  padding-bottom: 146px;
  background: #FFFFFF;
}

.s4-title {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 800;
  font-size: 52px;
  line-height: 110%;
  letter-spacing: -0.511589px;
  color: #292F36;
}

.s4-sub {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.424019px;
  color: #292F36;
}

.exec-name {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 110%;
  /* or 33px */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.424019px;
  text-align: center;
  color: #AAF0C1;
}

.exec-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */

  text-align: center;

  /* white */

  color: #FFFFFF;
}

.exec-card-txt {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.42px;
  color: #FFFFFF;
}

.sec5-dsk {
  position: relative;
  height: 356px;
  background: url('/img/dash-pattern.jpg');
}

.sec5-dsk-phone {
  position: absolute;
  left: 726px;
  top: -78px;
}

.sec5-xl-phone {
  position: absolute;
  left: 1100px;
  top: -78px;
}

.sec5-cnt {
  position: absolute;
  width: 516px;
  height: 157px;
  left: 794px;
  top: 75px;
  left: 214px;
}

.sec5-title {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 110%;
  /* or 46px */
  letter-spacing: -0.424019px;
  /* Color Palette/White (100%) */
  color: #FFFFFF;
}

.sec5-sub {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */
  /* Color Palette/White (100%) */
  color: #FFFFFF;
}

.apply-btn-txt {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 125%;
  letter-spacing: 0.00892857em;
  color: #292F36 !important;
}

.section6-desk {
  background: #FFFFFF;
  padding-top: 93px;
  padding-left: 147px;
  padding-right: 147px;
  padding-bottom: 87px;
}

.news-section {
  padding: 5rem 4rem 7rem 4rem;
  background: #FFFFFF;
}

.news-section h1 {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 110%;
  letter-spacing: -0.424019px;
  color: #292F36;
}

.news-section h1.news-title {
  color: #005F6C;
}

.news-section p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  text-align: justify;
  color: #292F36;
  margin-bottom: 2rem;
}

.learn-btn {
  width: 137px;
  height: 33px;
  border: 1.37127px solid #005F6C;
  border-radius: 5.48507px;
  background: #FFFFFF !important;
}

.lrn-btn-txt {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16.5px;
  line-height: 125%;
  /* identical to box height, or 21px */
  padding-top: 5px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.00892857em;
  text-transform: uppercase;
  /* midnight eagle green */
  color: #005F6C;
}

.v-card-news {
  border-radius: 10px;
}

.v-card-news h1 {
  width: 320px;
  height: 33px;

  font-family: 'Sora';
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.424019px;
  color: #00A1B7;
}

.v-card-news p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 125%;
  /* or 25px */

  text-align: center;
  letter-spacing: -0.202611px;

  /* gunmetal */

  color: #292F36;
}

/*---------------------TABLET---------------------*/
.section1-tablet {
  position: relative;
  height: 400px;
  background-image: url('/img/news/hero-section.jpg');
  background-position: center;
  background-size: cover;
}

.s1-title-tablet {
  position: absolute;
  top: 250px;
  left: 50px;
  font-family: 'Sora';
  font-style: normal;
  font-weight: 800;
  font-size: 52px;
  line-height: 110%;
  letter-spacing: -0.511589px;
  color: #FFFFFF;
}

.section2-tablet {
  background: #FFFFFF;
  padding-top: 93px;
}

.s2-title-tablet {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 800;
  font-size: 38px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.424019px;
  color: #292F36;
}

.section3-tablet {
  height: 590px;
  background-image: url('/img/secondary-knit.jpg');
  background-position: center;
  background-size: cover;

}

.section4-tablet {
  padding-top: 79px;
  background: #FFFFFF;
}

.sec5-tablet {
  height: 300px;
  background-image: url('/img/dash-pattern.jpg');
  background-position: center;
  background-size: cover;
}

.section6-tablet {
  background: #FFFFFF;
  padding-top: 93px;
  padding-bottom: 87px;
}

/*---------------------MOBILE---------------------*/
.section1-mobile {
  position: relative;
  height: 385px;
  background-image: url('/img/news/hero-m.jpg');
  background-position: center;
  background-size: cover;
}

.s1-title-mobile {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 110%;
  letter-spacing: -0.511589px;
  color: #FFFFFF;
}

.section2-mobile {
  background-image: url('/img/white-pattern-mobile-pet.jpg');
  background-position: center;
  background-size: cover;
}

.s2-title-tablet {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.424019px;
  color: #292F36;
}

.section3-mobile {
  background-image: url('/img/secondary-knit.jpg');
  background-position: center;
  background-size: cover;
}

.section4-tablet {
  padding-top: 79px;
  background: #FFFFFF;
}

.sec5-mobile {
  background-image: url('/img/dash-pattern.jpg');
  background-position: center;
  background-size: cover;
}

.section6-mobile {
  background-image: url('/img/white-pattern-mobile-pet.jpg');
  padding-top: 93px;
  padding-bottom: 87px;
}

.snow-white {
  background-color: #FFFFFF;
}

.s2-big-text-mobile {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.511589px;
  color: #005F6C;
}

.s4-title-mobile {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 110%;
  letter-spacing: -0.511589px;
  color: #292F36;
}

.s4-sub-mobile {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.424019px;
  color: #292F36;
}

.white-bar-tablet {
  height: 110px;
  background: #FFFFFF;
}

/* override */

.s1-title-desk {
  top: 470px;
  width: 50%;
}

@media screen and (max-width: 1342px) {
  .section1-desk {
    background-position: 80%;
  }
  .s1-title-desk {
    top: 32rem;
    left: 6rem;
    width: 80%;
  }
}

@media screen and (max-width: 710px) {
  .section1-desk {
    height: 500px;
  }
  .s1-title-desk {
    top: 25rem;
    left: 4rem;
    font-size: 42px;
    width: 85%;
  }
}

@media screen and (max-width: 520px) {
  .section1-desk {
    height: 450px;
  }
  .s1-title-desk {
    top: 22rem;
    left: 2.5rem;
    font-size: 2.3rem;
    width: 80%;
  }
}

.bnc-logo {
  width: 50%;
  margin: auto;
}

@media screen and (max-width: 820px) {
  .bnc-logo {
    width: 90%;
  }
  .news-section h1 {
    font-size: 30px;
    line-height: 110%;
    letter-spacing: -0.424019px;
  }
  .news-section p {
    font-size: 20px;
    line-height: 150%;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 520px) {
  .bnc-logo {
    width: 100%;
  }
  .news-section {
    padding: 5rem 2rem 7rem 2rem;
  }
  .news-section h1 {
    font-size: 26px;
  }
  .news-section p {
    font-size: 16px;
  }

  .news-section span.date {
    font-size: 16px;
  }
  @media screen and (min-width: 1024px) {
    .blanket-in-partnership-with-humania .v-image__image.v-image__image--cover {
      background-size: 60%;
    }
  }
  .section1-xl.blanket-in-partnership-with-humania {
    background-image: none !important;
    background-color: #fff !important;
  }
}
</style>
